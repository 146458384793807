import {
  rootstockTestnet,
  rootstock,
  kakarotSepolia,
  boba,
  bobaSepolia,
  skaleEuropa,
  skaleEuropaTestnet,
} from "wagmi/chains";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { bellecourChain } from "./web3-mail-chain";

export const wagmiConfig =
  process.env.REACT_APP_ENV === "development"
    ? getDefaultConfig({
        appName: "QSTN",
        projectId: process.env.REACT_APP_WAGMI_PROJECT_ID ?? "",
        chains: [rootstockTestnet, bellecourChain, kakarotSepolia, bobaSepolia, skaleEuropaTestnet],
        ssr: true,
      })
    : getDefaultConfig({
        appName: "QSTN",
        projectId: process.env.REACT_APP_WAGMI_PROJECT_ID ?? "",
        chains: [rootstock, bellecourChain, kakarotSepolia, boba, skaleEuropa],
        ssr: true,
      });
