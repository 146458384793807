export const ROUTES = {
  landing: "/",
  login: "login",
  registration: "registration",
  dashboard: "dashboard",
  business: "business",
  user: "user",
  discover: "discover",
  survey: "survey",
  businesses: "businesses",
  surveyFilling: "survey-filling",
  leaderBoards: "leader-boards",
  vaultManager: "vault-manager",
  plans: "plans",
  subscriptionPlans: "subscription-plans",
  createAccount: "create-account",
  forgotPassword: "forgot-password",
  onboarding: "onboarding",
  analytics: "analytics",
  campaigns: "campaigns",
  users: "users",
  profile: "profile",
  balance: "balance",
  subscriptions: "subscriptions",
  settings: "settings",
  surveys: "surveys",
  referrals: "referrals",
  medias: "medias",
  createQuiz: "create-quiz",
  createSurvey: "create-survey",
  paymentMethod: "payment-method",
  payWithCrypto: "pay-with-crypto",
  payWithCard: "pay-with-card",
  selectPackage: "select-package",
  surveySettings: "survey-settings",
  update: "update",
  updateAnswers: "update-answers",
  whitelist: "whitelist",
  complete: "complete",
  reward: "reward",
  protect: "protect-data",
  answers: "answers",
  surveyUsers: "survey-users",
  verifyKyc: "verify-kyc",
  createNFT: "create-nft",
  profileInfo: "profile-info",
  surveyAccessGating: "survey-access-gating",
  rewardConditions: "manage-reward-conditions",
  connectNetwork: "connect-network",
  admin: "admin",
  stripe: "stripe",
  stripeSetup: "stripe-setup",
  web3Mail: "web3-mail",
  onboardingInvite: "onboarding-invite",
  forYou: "for-you",
  ghost: "ghost",
};

export const ROUTE_PARAMS = {
  surveyId: "surveyId",
  surveyFillingId: "surveyFillingId",
  surveyFillingUserId: "surveyFillingUserId",
  surveyNetwork: "surveyNetwork",
  businessDid: "businessDid",
  accountType: "accountType",
  paymentSuccess: "paymentSuccess",
};

export const SEARCH_PARAMS = {
  refId: "refId",
  surveyId: "surveyId",
  fractalCode: "code",
  fractalState: "state",
};
