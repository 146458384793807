export { isAddress, truncateHash, getScanLink, getScanLinkSolana, getScanLinkNear } from "./utils";
export { ZERO, CONTRACTS_NAMES, CONTRACTS_ADDRESSES, PROGRAM_ID, PROGRAM_NFT_ID, SOL_CONTROLLER } from "./constants";
export { bundler, paymaster } from "./biconomy";
export {
  Network,
  RPC_URLS,
  CHAIN_IDS,
  NATIVE_CURRENCIES_NAMES,
  NATIVE_CURRENCIES_ICONS,
  VIEM_CHAINS,
  SHORT_CHAIN_NAMES,
  SUPPORTED_POLYGON_CHAIN_ID,
  SUPPORTED_TON_CHAIN_ID,
  SUPPORTED_POLYGON_NETWORK,
  SUPPORTED_TON_NETWORK,
  SCAN_URLS,
  SUPPORT_SOLANA_NETWORK,
  SUPPORTED_SOLANA_CHAIN_ID,
  BLOCK_EXPLORER_URLS_SOLANA,
  SUPPORTED_NEAR_CHAIN_ID,
  SUPPORTED_ROOTSTOCK_CHAIN_ID,
  SUPPORT_ROOTSTOCK_NETWORK,
  SUPPORTED_NEAR_NETWORK,
  BLOCK_EXPLORER_URLS_NEAR,
  SUPPORTED_KAKAROT_CHAIN_ID,
  SUPPORT_KAKAROT_NETWORK,
  SUPPORTED_BOBA_CHAIN_ID,
  SUPPORT_BOBA_NETWORK,
  SUPPORTED_SKALE_CHAIN_ID,
  SUPPORT_SKALE_NETWORK,
  VIEM_CHAINS_IDS,
  MAINNET_CHAIN_IDS,
  TESTNET_CHAIN_IDS,
  WORK_CHAIN_IDS,
  MIN_MAX_AMOUNTS,
} from "./chains";

export { NearContract, NetworkId, NearNftContract } from "./near";

// TODO add URL to mainnet
export const TON_NFT_COLLECTION_URL =
  process.env.REACT_APP_ENV === "development"
    ? "https://testnet.getgems.io/collection/"
    : "https://getgems.io/collection/";

export { wagmiConfig } from "./wagmi";
